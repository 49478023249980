@import 'src/shared/style/colors.scss';
$start-color: #84bcd5;
$end-color: #1f8dc5;

.sc-btn-primary {
  background: $color-primary;
  color: #000;
  border: none;

  &:hover,
  &:focus {
    color: #000;
    background: $color-primary;
  }

  &.disabled {
    cursor: not-allowed;
    background: rgba(198, 207, 214, 0.42) 0% 0% no-repeat padding-box;
    color: #000;
    &:hover,
    &:focus {
      background: rgba(198, 207, 214, 0.42) 0% 0% no-repeat padding-box;
      color: #000;
    }
  }
}

.sc-btn-secondary {
  background: linear-gradient(to right, $start-color, $end-color);
  color: #000;
  border: none;

  &:hover,
  &:focus {
    background: linear-gradient(to right, $start-color, lighten($end-color, 5));
  }

  &.grad-top {
    background: linear-gradient(to top, $start-color, $end-color);

    &:hover,
    &:focus {
      background: linear-gradient(to top, $start-color, lighten($end-color, 5));
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: rgba(198, 207, 214, 0.42) 0% 0% no-repeat padding-box;
    color: #000;
    &:hover,
    &:focus {
      background: rgba(198, 207, 214, 0.42) 0% 0% no-repeat padding-box;
      color: #000;
    }
  }
}

.sc-btn-cancel {
  background: rgba(198, 207, 214, 0.42);
  border: none;
  color: #000;

  &:hover,
  &:focus {
    background: lighten(rgba(198, 207, 214, 0.42), 5);
    color: #000;
  }
}
