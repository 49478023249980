$color-white: #ffffff;
$color-grey: #5e6c75;
$color-title-grey: #778899;
$color-primary: #f1c400;
$color-secondary: #5bc2e7;
$color-secondary-dark: #1f8dc5;
$color-danger: #8d0707;

$color-border: #d9d9d9;
$color-border-light: #e6e6e6;
