@import 'src/shared/style/colors.scss';
@import 'src/shared/style/buttons.scss';

%title {
  color: $color-primary;
  font-weight: 400;
  text-transform: uppercase;
  font-family: Raleway, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial,
    sans-serif;
}

html {
  color: rgba(0, 0, 0, 0.75);
}

* {
  box-sizing: border-box;
  word-break: keep-all;
  word-wrap: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial,
    sans-serif;
  color: rgba(0, 0, 0, 0.75);
  margin: 0;
}

p {
  color: $color-grey;
}

th {
  background: #fff;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn-main {
  background: linear-gradient(
    to bottom,
    $color-secondary-dark,
    darken($color-secondary-dark, 10)
  );
  border: none;

  &:hover,
  &:focus {
    // background: linear-gradient(to bottom, darken($color-secondary-dark, 5), darken($color-secondary-dark, 15));
    background: linear-gradient(
      to bottom,
      $color-secondary-dark,
      darken($color-secondary-dark, 10)
    );
  }
}

.no-margin {
  margin: 0;
}

.btn-add-container {
  margin: 20px 0 20px;
  display: flex;
  align-items: center;
}

.btn-add {
  margin-right: 10px;
  background-color: $color-primary;
  border-color: $color-primary;

  > i {
    font-size: 26px;
    margin: 2px 0 0;
  }
}

.section-title {
  @extend %title;
  font-size: 18px;
}

.subtitle {
  @extend %title;
  font-size: 15px;
}

.ant-btn-link {
  padding: 0;
}

.ant-layout-sider {
  flex-basis: auto !important;
  background-color: #f8f8f8;
}

.ant-btn-dangerous,
.ant-btn-dangerous:focus {
  color: $color-danger;
  border-color: $color-danger;
}

.ant-btn-dangerous:hover {
  color: lighten($color-danger, 30%);
  border-color: lighten($color-danger, 30%);
}

.ant-btn-dangerous.ant-btn-link,
.ant-btn-dangerous.ant-btn-link:focus {
  color: $color-danger;
}

.ant-btn-dangerous.ant-btn-link:hover {
  color: lighten($color-danger, 30%);
}

.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: $color-primary;
}

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col {
  padding-left: 0 !important;
}
