@import 'src/shared/style/colors.scss';

.survey-form {
  h1 {
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    padding: 48px 0 24px;
    text-align: center;
  }

  &-desc {
    padding: 24px 0;
    text-align: center;
  }

  &-fields {
    padding: 0 48px;
  }

  &-actions {
    display: flex;
    align-items: flex-end;
    margin-top: 48px;

    button {
      border-radius: 0;
      border: none;
      width: 50%;
    }
  }
}
