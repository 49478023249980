.survey {
  &-content {
    width: 100%;
    min-height: 60vw;
    padding: 320px 10vw 40px 10vw;
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
  }
}

.error-boundary {
  margin-top: 180px;
}
